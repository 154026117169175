<template>
	<div v-loading="fullscreenLoading">
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">{{ $lang('tmdDOTco.01302', $route) }}</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						{{ $lang('tmdDOTco.01303', $route) }}
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"
				 v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">{{ $lang('tmdDOTco.01304', $route) }}</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm :title="$lang('tmdDOTco.01305', $route)" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">{{ $lang('tmdDOTco.01306', $route) }}</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					{{ $lang('tmdDOTco.01307', $route) }}
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01308', $route) }}</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01309', $route)">
					<el-input :placeholder="$lang('tmdDOTco.01310', $route)" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? $lang('tmdDOTco.01311', $route) : $lang('tmdDOTco.01312', $route)" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01313', $route)" prop="name">
					<el-input v-model="addressForm.name" :placeholder="$lang('tmdDOTco.01314', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01315', $route)" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" :placeholder="$lang('tmdDOTco.01316', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01317', $route)">
					<el-input v-model="addressForm.telephone" :placeholder="$lang('tmdDOTco.01318', $route)"></el-input>
				</el-form-item>

				<el-form-item class="area" :label="$lang('tmdDOTco.01319', $route)" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" :placeholder="$lang('tmdDOTco.01320', $route)">
								<el-option :label="$lang('tmdDOTco.01321', $route)" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" :placeholder="$lang('tmdDOTco.01322', $route)">
								<el-option :label="$lang('tmdDOTco.01323', $route)" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" :placeholder="$lang('tmdDOTco.01324', $route)">
								<el-option :label="$lang('tmdDOTco.01325', $route)" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01326', $route)" prop="address">
					<el-input v-model="addressForm.address" :placeholder="$lang('tmdDOTco.01327', $route)"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $lang('tmdDOTco.01328', $route) }}</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">{{ $lang('tmdDOTco.01329', $route) }}</el-button>
			</span>
		</el-dialog>

		<!--使用余额-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01330', $route) }}</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">{{ $lang('tmdDOTco.01331', $route) }}</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">{{ $lang('tmdDOTco.01332', $route) }}</div>
				<div class="clear"></div>
			</div>
		</div>

		<!--使用积分抵扣-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.max_usable_point > 0">
			<div class="block-text">{{ $lang('tmdDOTco.01333', $route) }}</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_point ? '' : 'active'" @click="usePoint(0)">{{ $lang('tmdDOTco.01334', $route) }}</div>
				<div class="pay-type-item" :class="orderCreateData.is_point ? 'active' : ''" @click="usePoint(1)">{{ $lang('tmdDOTco.01335', $route) }}</div>
				<div class="clear"></div>
			</div>
		</div>

		<!-- 配送方式 -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.shop_goods_list.express_type.length > 1">
			<div class="block-text">{{ $lang('tmdDOTco.01336', $route) }}
				<span class="distribution" v-if="orderPaymentData.delivery_type=='store'">{{orderCreateData.delivery.store_name}}</span>
			</div>
			<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
			 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem, orderPaymentData.site_id, orderPaymentData.shop_goods_list.express_type)"
			 :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''" v-if="deliveryItem.name != 'local'">
				{{ deliveryItem.title }}
			</div>

		</div>
		
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.invoice">
			<div class="block-text">{{ $lang('tmdDOTco.01337', $route) }}</div>
			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 0 ? 'active' : ''" @click="changeIsInvoice">{{ $lang('tmdDOTco.01338', $route) }}</div>
				<div class="pay-type-item" :class="orderCreateData.is_invoice == 1 ? 'active' : ''" @click="changeIsInvoice">{{ $lang('tmdDOTco.01339', $route) }}</div>
				<div class="clear"></div>
			</div>
			<div class="invoice-information" v-if="orderCreateData.is_invoice == 1">
				<div class='invoice-title'>
					<div class="invoice-type-box invoice-title-box">
						<span class="invoice-name">{{ $lang('tmdDOTco.01340', $route) }}</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 1 ? 'active' : '' " @click="clickType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 1"></i>
							<input type="radio" name="invoice_type" value="1" @click="clickType(1)" :checked="orderCreateData.invoice_type == 1 ? 'checked' : '' "/> -->
							{{ $lang('tmdDOTco.01341', $route) }}
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_type == 2 ? 'active' : '' "  @click="clickType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_type == 2"></i>
							<input type="radio" name="invoice_type" value="2" @click="clickType(2)" :checked="orderCreateData.invoice_type == 2 ? 'checked' : '' "/> -->
							{{ $lang('tmdDOTco.01342', $route) }}
						</label>
					</div>
					<div class="invoice-type-box invoice-title-box">
						<span class="invoice-name">{{ $lang('tmdDOTco.01343', $route) }}</span>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 1 ? 'active' : '' "  @click="clickTitleType(1)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 1"></i>
							<input type="radio" name="invoice_rise_type" value="1" @click="clickTitleType(1)" :checked="orderCreateData.invoice_title_type == 1 ? 'checked' : '' "/> -->
							{{ $lang('tmdDOTco.01344', $route) }}
						</label>
						<label class="invoice-to-type">
							<i class="invoice-i-input" :class="orderCreateData.invoice_title_type == 2 ? 'active' : '' "  @click="clickTitleType(2)"></i>
							<!-- <i v-if="orderCreateData.invoice_title_type == 2"></i>
							<input type="radio" name="invoice_rise_type" value="2" @click="clickTitleType(2)" :checked="orderCreateData.invoice_title_type == 2 ? 'checked' : '' "/> -->
							{{ $lang('tmdDOTco.01345', $route) }}
						</label>
					</div>
				</div>
				<div class="invoice-type-box">
					<span class="invoice-name">{{ $lang('tmdDOTco.01346', $route) }}</span>
					<div class="invoice-box-form">
						<input type="text" :placeholder="$lang('tmdDOTco.01347', $route)" v-model.trim="orderCreateData.invoice_title"/>
						<input type="text" :placeholder="$lang('tmdDOTco.01348', $route)" v-model.trim="orderCreateData.taxpayer_number" v-if="orderCreateData.invoice_title_type == 2"/>
						<input type="text" :placeholder="$lang('tmdDOTco.01349', $route)" v-model.trim="orderCreateData.invoice_full_address" v-if="orderCreateData.invoice_type == 1"/>
						<input type="text" :placeholder="$lang('tmdDOTco.01350', $route)" v-model.trim="orderCreateData.invoice_email" v-if="orderCreateData.invoice_type == 2"/>
					</div>
				</div>
				<div class="invoice-condition">
					<span class="invoice-name">{{ $lang('tmdDOTco.01351', $route) }}</span>
					<div class="invoice-box-form">
						<span class="option-item" 
						:key="index"	
						v-for="(item, index) in orderPaymentData.shop_goods_list.invoice.invoice_content_array"
						@click="changeInvoiceContent(item)"
						:class="{ 'color-base-bg active': item == orderCreateData.invoice_content }"
						>
						{{item}}
						</span>
					</div>
				</div>
			</div>
		
		</div>
		
		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="50%">{{ $lang('tmdDOTco.01352', $route) }}</td>
						<td width="12.5%">{{ $lang('tmdDOTco.01353', $route) }}</td>
						<td width="12.5%">{{ $lang('tmdDOTco.01354', $route) }}</td>
						<td width="12.5%">{{ $lang('tmdDOTco.01355', $route) }}</td>
					</tr>
				</table>
			</div>
		</div>
		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="50%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- 规格 -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-price">{{ $lang('components.currency_symbol_left', $route) + goodsItem.price + $lang('components.currency_symbol_right', $route) }}</td>
							<td width="12.5%" class="goods-num">{{ goodsItem.num }}</td>
							<td width="12.5%" class="goods-money">{{ $lang('components.currency_symbol_left', $route) + (goodsItem.price * goodsItem.num).toFixed(2) + $lang('components.currency_symbol_right', $route) }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<!--优惠券-->
		<div class="item-block" v-if="orderPaymentData.shop_goods_list.coupon_list.length">
			<div class="block-text">{{ $lang('tmdDOTco.01356', $route) }}</div>
			<div class="order-cell platform-coupon">
				<div v-if="orderPaymentData.coupon_money!=0">
					<span class="ns-text-color" @click="openPlatformCoupon">{{ $lang('tmdDOTco.01357', $route) }}</span>
					<span class="ns-text-color" @click="openPlatformCoupon">
						<span class="inline">{{ $lang('components.currency_symbol_left', $route) }}</span>
						{{ orderPaymentData.coupon_money.toFixed(2) }}
						<span class="inline">{{ $lang('components.currency_symbol_right', $route) }}</span>
					</span>
				</div>

				<div v-else>
					<div class="box ns-text-color" @click="openPlatformCoupon">{{ $lang('tmdDOTco.01358', $route) }}</div>
				</div>
			</div>
		</div>

		<!-- 买家留言 -->
		<div class="item-block padd-bom-10">
			<div class="block-text">{{ $lang('tmdDOTco.01359', $route) }}</div>
			<el-input rows="3" type="textarea" :placeholder="$lang('tmdDOTco.01360', $route)" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>
		<!-- 总计 -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">{{ $lang('tmdDOTco.01361', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.goods_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">{{ $lang('tmdDOTco.01362', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.delivery_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01363', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.invoice_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_delivery_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01364', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.invoice_delivery_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01365', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.promotion_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.point_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01366', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.point_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.coupon_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01367', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.coupon_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01368', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.balance_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--结算-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					{{ $lang('tmdDOTco.01369L', $route) + orderPaymentData.goods_num + $lang('tmdDOTco.01369R', $route)}}
					<div class="ns-text-color">{{ $lang('components.currency_symbol_left', $route) }} {{ orderPaymentData.pay_money | moneyFormat}}  {{$lang('components.currency_symbol_right', $route) }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">{{ $lang('tmdDOTco.01370', $route) }}</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--选择平台优惠券弹框-->
		<el-dialog :title="$lang('tmdDOTco.01371', $route)" :visible.sync="dialogPlatcoupon" width="50%" @close="savePlatformCoupon()">
			<el-table ref="platformCouponTable" :data="orderPaymentData.shop_goods_list.coupon_list" highlight-current-row
			 @row-click="selectPlatformCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="platformCouponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="platformcoupon_name" :label="$lang('tmdDOTco.01372', $route)" width="200">
					<template slot-scope="scope">
						<span>{{ scope.row.coupon_name }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" :label="$lang('tmdDOTco.01373', $route)"></el-table-column>
				<el-table-column :label="$lang('tmdDOTco.01374', $route)">
					<template slot-scope="scope">
						<span class="ns-text-color-gray ns-font-size-sm" v-if="scope.row.at_least > 0">{{ $lang('tmdDOTco.01375L', $route) + scope.row.at_least + $lang('tmdDOTco.01375R', $route) }}</span>
						<span class="ns-text-color-gray ns-font-size-sm" v-else>{{ $lang('tmdDOTco.01376', $route) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$lang('tmdDOTco.01377', $route)">
					<template slot-scope="scope">
						<span>{{ $util.timeStampTurnTime(scope.row.end_time) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogPlatcoupon = false">{{ $lang('tmdDOTco.01378', $route) }}</el-button>
				<el-button @click="dialogPlatcoupon = false" type="primary">{{ $lang('tmdDOTco.01379', $route) }}</el-button>
			</div>
		</el-dialog>
		<!--配送方式  门店 -->
		<el-dialog :title="$lang('tmdDOTco.01380', $route)" :visible.sync="dialogStore" width="50%">

			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" :label="$lang('tmdDOTco.01381', $route)" width="160"></el-table-column>
				<el-table-column prop="store_address" :label="$lang('tmdDOTco.01382', $route)"></el-table-column>
				<el-table-column prop="open_date" :label="$lang('tmdDOTco.01383', $route)"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- 配送方式 外卖 -->
		<!-- 		<el-dialog title="送达时间" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="送达时间">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" placeholder="选择时间" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">关闭</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">确认选择</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog> -->

		<!-- 支付密码 -->
		<el-dialog :title="$lang('tmdDOTco.01384', $route)" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>{{ $lang('tmdDOTco.01385', $route) }}</p>
				<p>{{ $lang('tmdDOTco.01386', $route) }}</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">{{ $lang('tmdDOTco.01387', $route) }}</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">{{ $lang('tmdDOTco.01388', $route) }}</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item :label="$lang('tmdDOTco.01389', $route)" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">{{ $lang('tmdDOTco.01390', $route) }}</p>
			</el-form>
		</el-dialog>

		<!--选择优惠券弹框-->
		<!-- <el-dialog title="选择优惠券" :visible.sync="dialogCoupon" width="50%">
			<el-table ref="couponTable" :data="siteCoupon.data" highlight-current-row @row-click="selectCoupon" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<div class="disabled-selected-wrap">
							<el-radio v-model="couponRadio" :label="scope.row"><i></i></el-radio>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="coupon_name" label="名称" width="200"></el-table-column>
				<el-table-column label="优惠">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 'reward'">{{ scope.row.money }}</span>
						<span v-if="scope.row.type == 'discount'">{{ scope.row.discount }} 折</span>
					</template>
				</el-table-column>
				<el-table-column prop="use" label="使用"></el-table-column>
				<el-table-column prop="time" label="期限"></el-table-column>
			</el-table>
			<br />
			<div class="align-right">
				<el-button @click="dialogCoupon = false">取消</el-button>
				<el-button @click="saveCoupon()" type="primary">确认选择</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import mixin from './payment.js';
	export default {
		name: 'payment',
		mixins: [mixin]
	};
</script>

<style lang="scss" scoped>
	@import './payment.scss';
</style>
